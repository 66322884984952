@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Aldrich", sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  @apply overflow-hidden h-screen;
}

@font-face {
  font-family: "Nasalization";
  src: url("./assets/fonts/NASALIZA.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Nasalizationrg";
  src: url("./assets/fonts/nasalization-rg.ttf") format("truetype");
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.neon-text {
  color: #084A29;
text-shadow: 0px 0px 16px #4F0;
-webkit-text-stroke-width: 4px;
-webkit-text-stroke-color: #1BD478;
font-family: Nasalization;
font-size: 93px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
}

.normal-text {
color: #FFF;
font-family: Nasalizationrg;
font-size: 77px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
}

@media (max-width: 1024px) {
  .neon-text {
    font-size: 52px;
    -webkit-text-stroke-width: 2px;
  }
  .normal-text {
    font-size: 42px;
  }
}

@media (min-width: 1636px) {
  .neon-text {
    font-size: 116px;
    -webkit-text-stroke-width: 5px;
  }
  .normal-text {
    font-size: 96px;
  }
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.loading-bar {
  animation: loading 1s linear;
}

.hero-img {
  bottom: -15px;
}

@media (min-height: 780px) {
  .hero-img {
    bottom: 45px;
  }
}
